import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import LanguageSwitcher from './components/LanguageSwitcher';
import './css/Table.css';
import './css/Modal.css';

const socket = io({
  transports: ['websocket'],
  upgrade: false,
});

const Table = ({ number, isActive, onClick, isCircle }) => {
  return (
    <div className={`table ${isActive ? 'active' : ''} ${isCircle ? 'circle' : 'square'}`} onClick={onClick}>
      {number}
    </div>
  );
};

const TableView = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isSoundEnabled, setIsSoundEnabled] = useState(() => localStorage.getItem('isSoundEnabled') === 'true');
  const [adminSessionToken, setAdminSessionToken] = useState(() => localStorage.getItem('adminSessionToken') || '');
  const audioRef = useRef(new Audio('/notification.mp3'));
  const [blinkInterval, setBlinkInterval] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem('adminSessionToken');
      console.log('Authorization token being sent:', token); // Log the token being sent
      
      if (!token) {
        console.error('No admin session token found');
        await handleAdminLogin(); // Try to log in if no token is found
        return;
      }
    
      try {
        const response = await axios.get('/api/orders', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
        if (error.response && error.response.status === 401) {
          handleSessionExpiration();
        }
      }
    };
    
    fetchOrders();

    socket.on('orderPlaced', (order) => {
      console.log('Order placed:', order);
      setOrders((prevOrders) => [...prevOrders, order]);
      if (isSoundEnabled) {
        playSound();
      }
      startBlinking();
    });

    socket.on('orderUpdated', (updatedOrder) => {
      console.log('Order updated:', updatedOrder);
      setOrders((prevOrders) =>
        prevOrders.map((order) => (order._id === updatedOrder._id ? updatedOrder : order))
      );
    });

    return () => {
      socket.off('orderPlaced');
      socket.off('orderUpdated');
      stopBlinking();
    };
  }, [isSoundEnabled, adminSessionToken]);

  const handleAdminLogin = async () => {
    try {
      const response = await axios.post('/api/admin/login', {
        username: 'admin',
        password: 'beachbaradmin2024'
      });
      console.log('Response from server:', response.data);  // Log the full response from server
      
      const { token } = response.data;
  
      if (token) {
        localStorage.setItem('adminSessionToken', token);  // Store token in localStorage
        setAdminSessionToken(token);
        console.log('Admin session token successfully stored:', token); // Log success
      } else {
        console.error('No token received from server');
      }
    } catch (error) {
      console.error('Error logging in as admin:', error);
    }
  };
  
  useEffect(() => {
    const storedToken = localStorage.getItem('adminSessionToken');
    console.log('Stored token on app load:', storedToken); // Log the token on page load
    if (storedToken) {
      setAdminSessionToken(storedToken);  // Set the token from localStorage if it exists
    }
  }, []);
  

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    stopBlinking();
  };

  const handleOrderDone = async () => {
    try {
      if (!adminSessionToken) {
        console.error('No admin session token found');
        await handleAdminLogin(); // Try to login as admin if no token is found
      }

      await axios.put(`/api/orders/${selectedOrder._id}`, { status: 'done' }, {
        headers: { Authorization: `Bearer ${adminSessionToken}` }
      });
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === selectedOrder._id ? { ...order, status: 'done' } : order
        )
      );
      setSelectedOrder(null);
    } catch (error) {
      console.error('Error updating order status:', error);
      if (error.response && error.response.status === 401) {
        handleSessionExpiration();
      }
    }
  };

  const handleSessionExpiration = () => {
    alert('Your session has expired. Please log in again.');
    localStorage.removeItem('adminSessionToken');
    setAdminSessionToken('');
    setOrders([]);
  };
  
  const generateTables = (start, end, isCircle = false, prefix = '') => {
    const tables = [];
    for (let i = start; i <= end; i++) {
      const tableNumber = isCircle ? `${prefix}${i - start + 1}` : i.toString();
      const order = orders.find(order => order.table === tableNumber && order.status === 'pending');
      tables.push(
        <Table
          key={tableNumber}
          number={tableNumber}
          isActive={!!order}
          onClick={() => handleOrderClick(order)}
          isCircle={isCircle}
        />
      );
    }
    return tables;
  };

  const calculateTotal = (order) => {
    return order.reduce((total, item) => {
      const price = parseFloat(item.item.split('-')[1].trim().split(' ')[0]);
      return total + (price * item.quantity);
    }, 0);
  };

  const calculateTotalItems = (order) => {
    return order.reduce((total, item) => total + item.quantity, 0);
  };

  const playSound = async () => {
    console.log('Playing sound');
    try {
      if (Notification.permission === 'granted') {
        new Notification('New Order!', { body: 'You have a new order!' });
      } else if (Notification.permission === 'default') {
        await Notification.requestPermission();
      }

      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.volume = 1.0;
      await audioRef.current.play();
    } catch (error) {
      console.error('Error with notification or sound:', error);
    }
  };

  const startBlinking = () => {
    if (blinkInterval) return;
    console.log('Starting blinking');
    const originalTitle = document.title;
    let isOriginal = true;
    const interval = setInterval(() => {
      document.title = isOriginal ? 'New Order!' : originalTitle;
      isOriginal = !isOriginal;
    }, 1000);
    setBlinkInterval(interval);
  };

  const stopBlinking = () => {
    console.log('Stopping blinking');
    clearInterval(blinkInterval);
    setBlinkInterval(null);
    document.title = 'Your App Title';
  };

  const enableSound = () => {
    console.log('Sound enabled');
    setIsSoundEnabled(true);
    localStorage.setItem('isSoundEnabled', 'true');
  };

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then(permission => {
        console.log('Notification permission:', permission);
      });
    }
  }, []);

  const handleAdminLogsClick = async () => {
    try {
      const response = await axios.post('/api/admin/login', {
        username: 'admin',
        password: 'beachbaradmin2024'
      });
      const { token } = response.data;
      localStorage.setItem('adminSessionToken', token);
      setAdminSessionToken(token);
      window.location.href = '/admin/logs';
    } catch (error) {
      console.error('Error logging in as admin:', error);
    }
  };

  return (
    <div className="App">
      <LanguageSwitcher />
      {!isSoundEnabled && (
        <div className="modal">
          <div className="modal-content">
            <h2>Enable Sound</h2>
            <p>Please enable sound to receive notifications for new orders.</p>
            <button onClick={enableSound}>Enable Sound</button>
          </div>
        </div>
      )}
      <div className="row">
        {generateTables(1, 15)}
      </div>
      <div className="row">
        {generateTables(16, 23)}
      </div>
      <div className="row">
        {generateTables(24, 31, true, 'A')}
      </div>
      <div className="row">
        {generateTables(32, 39, true, 'B')}
      </div>
      <div className="row">
        {generateTables(40, 51, true, 'C')}
      </div>
      <div className="row">
        {generateTables(52, 63, true, 'D')}
      </div>
      <div className="row">
        {generateTables(64, 75, true, 'E')}
      </div>
      <div className="row">
        {generateTables(76, 83, true, 'F')}
      </div>
      {selectedOrder && (
        <div className="modal">
          <div className="modal-content">
            <h2>Order Details</h2>
            <p>Table: {selectedOrder.table}</p>
            <ul>
              {selectedOrder.order.map((item, index) => (
                <li key={index}>
                  {item.item} - {item.quantity} {item.notes ? `/ Notes: ${item.notes}` : ''}
                </li>
              ))}
            </ul>
            <p><strong>Total Items: {calculateTotalItems(selectedOrder.order)}</strong></p>
            <p><strong>Total: {calculateTotal(selectedOrder.order)} LEK</strong></p>
            <button onClick={() => setSelectedOrder(null)}>Close</button>
            <button onClick={handleOrderDone}>Complete Order</button>
          </div>
        </div>
      )}
      <button className="admin-logs-button" onClick={handleAdminLogsClick}>
        Admin Logs
      </button>
    </div>
  );
};

export default TableView;
